@import url('https://fonts.googleapis.com/css?family=Jackey One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Average&display=swap');

.test {
    border: yellow 5px solid;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
}

:root {
    --color-primary: white;
    --bg-primary: #171515;
    --ff-primary: 'Allerta', sans-serif;
    --ff-secondary: 'Average', sans-serif;
}

body {
    background: var(--bg-primary);
    color: var(--color-primary);
    font-family: var(--ff-secondary);
    font-size: 1rem;
    font-weight: 400;
}

.clickable:hover {
    cursor: pointer;
}

.container {
    display: flex;
}

.center {
    justify-content: center;
}

h1, h2, h3 {
    font-family: var(--ff-primary);
}

@media (max-width: 600px) {

    .mobhide {
        display: none;
    }

}

.swap-flex {
    flex-direction: column;
}

.mar-top {
    margin-top: 2em;
}



#index {
    max-width: 100vw;
}

.section-div{
    margin-top: 250px;
}


@media (max-width: 600px) {

    .section-div {
        margin-top: 200px;
    }

}


#aboutus {
    font-size: 2rem;
    margin-top: 2em;
    margin-bottom: 1em;
}

.popis {

    margin-left: 20vw;
    margin-right: 20vw;
    font-size: 1.5em;

}

.oddal {
    margin-left: calc(20vw + 336px);
}


#homepage {
    background-image: url('./components/img/bg.gif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1500;
    height: 100vh;
}

.menu_item {
    color: var(--color-primary);
    background: transparent;
    border: 0px transparent solid;
    font-size: 1.3rem;
    font-family: var(--ff-primary);
    font-weight: 550;
    border-left: var(--color-primary) 2px solid;
    padding-left: 0.3em;
}

#Navbar {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}


#collapselogo {
    margin-bottom: 10em;
}

@media (max-width: 600px) {
    
    #collapselogo {
        margin-bottom: 0em;
    }

    #homepage{
        background-image: url('./components/img/wall.jpg');
    }

}


#first_line {
    border-top: var(--color-primary) 2px solid;
    margin-top: 0.8em;
    max-width: 96vw;
    margin-left: 2vw;
}








#playlist{
    font-size: 1.8rem;
    margin-bottom: 3em;
}

#songs {
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.songlist {
    border-left: var(--color-primary) 2px solid;
    padding-left: 0.5em;
}

@media (max-width: 600px) {

    #playlist {
        font-size: 1.1rem;
    }

    .songlist {
        font-size: 1em;
        width: 90%;
        margin-left: 5vw;
    }

    #dolni-block {
        margin-top: 2em;
    }

    .mar-bot {
        margin-bottom: 2em;
    }
}

@media (max-width: 870px) {

    .songlist {
        width: 100%;
    }
}

.blesk {
    position: relative;
}

.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.b1 {
    left: 80vw;
    top: -100px;

}

.b2 {
    left: 80vw;
}







#song {
    font-size: 1.8rem;
    margin-bottom: 3em;
}

#song-logo {
    max-width: 70vw;
    max-height: 500px;
    border: solid var(--color-primary) 2px;
    padding: 1em;
    padding-inline: 2em;
}

@media (max-width: 600px) {

    #song {
        font-size: 1.1rem;
    }
}

.b3 {
    left: 7vw;
}

.b4 {
    left: 76vw;
}






#kalendar {
    margin-bottom: 2em;
    font-size: 1.8rem;
}

.koncerty {
    font-size: 2rem;
    font-family: var(--ff-primary);
    text-align: center;
}

.koncert {
    margin-bottom: 0.5em;
}

@media (max-width: 600px) {

    .koncert {
        font-size: 1.1rem;
    }

    #kalendar {
        font-size: 1.1rem;
    }
}


#obchod {
    margin-bottom: 2em;
    font-size: 1.8rem;
}

.shop-pic {
    max-width: 300px;
    max-height: 300px;
    border: solid var(--color-primary) 1px;
    padding: 0.3em;
}

.shop {
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1.4rem;
}

.shop-item {
    background: transparent;
    border: none;
    color: var(--color-primary);
    font-size: 1rem;
    margin-left: 5px;
    margin-right: 5px;
}

@media (max-width: 600px) {

    #obchod {
        font-size: 1.1rem;
    }
}







#galerie {
    margin-bottom: 3em;
    font-size: 1.8rem;
}

@media (max-width: 600px) {

    #galerie {
        font-size: 1.1rem;
    }
}

.gal-foto {
    max-width: 120px;
    max-height: 120px;
}

.gal-video{
    max-width: 200px;
    max-height: 200px;
}

.gallery {
    justify-content: space-evenly;
}









#socials {
    margin-bottom: 2em;
    font-size: 1.8rem;
}

.social-text {
    display: inline-block;
    position: relative;
    bottom: 45px;
    color: var(--color-primary);
    margin-left: 10px;
}

.social-div {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 1.5em;
    margin-left: 15vw; 
}

.social-img {
    max-height: 120px;
    max-width: 170px;
}

@media (max-width: 670px) {

    #socials {
        font-size: 1.1rem;
    }

    .social-text {
        font-size: 1.4rem;
        display: block;
        bottom: -10px;
        margin-left: 0px;
    }

    .social-div {
        margin-left: 0;
        display: flex;
        justify-content: center;
    }
}







#kontakt {
    font-size: 1.8rem;
}

.mar-bot-all {
    margin-bottom: 2em;
}


.email-div {
    flex-wrap: wrap;
}


@media (max-width: 600px) {

    #kontakt {
        font-size: 1.1rem;
    }
    .email-text {
        margin-left: 0.4em;
        font-size: 1.4rem;
    }

}









#corner {
    position: fixed;
}

#cornerback {
    position: fixed;
    width: 420px;
    height: 420px;
    background: #E2E2E2;
    transform: rotate(45deg);
    z-index: -1;
    margin-top: -400px;
    margin-left: -250px;
    min-width: 405px;
}

#cornerback2 {
    position: fixed;
    width: 420px;
    height: 420px;
    background: #999999;
    transform: rotate(49deg);
    z-index: -1;
    margin-top: -400px;
    margin-left: -250px;
    min-width: 405px;
}

#cornerlogo {
    position: relative;
    margin-top:-30px;
    margin-left:-10px;
}

#burger_corner {
    position: absolute;
    width: 72px;
    height: 35px;
    left: 0px;
    top: 100px;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}


#rectangle_one {
    position: relative;
    width: 72px;
    height: 9px;
    left: 5px;
    top: 0px;
    
    background: #000000;
    border-radius: 16px;
}

#rectangle_two {
    position: relative;
    width: 62px;
    height: 9px;
    left: 5px;
    top: 5px;

    background: #000000;
    border-radius: 16px;
}

#rectangle_three {
    position: relative;
    width: 51px;
    height: 9px;
    left: 5px;
    top: 10px;

    background: #000000;
    border-radius: 16px;
}


#popup-div.slide-in {
    transition: 0.5s;
    width: 17em;
}


@media (max-width: 600px) {

    #popup-div.slide-in {
         width: 60vw;
    }

}


@media (max-width: 600px) {

    .popis {
        font-size: 1.3rem;
        margin-left: 10vw;
        margin-right: 10vw;
    }


}


#popup-div {
    position: fixed;
    top: 0;
    height: 100vh;
    transition: 0.5s;
    width: 0px;
    overflow: hidden;
    background-color: black;
    z-index: 100;
}


.popup_item {
    color: var(--color-primary);
    background: transparent;
    border: 0px transparent solid;
    font-size: 1.5rem;
    font-family: var(--ff-primary);
    font-weight: 550;
    display: block;
    margin-top: 1em;
    white-space: nowrap;
    text-align: center;
    position: relative;
    width: 100%;
}


#photos {
    font-size: 1.8rem;
}


#cookie-holder {
    position: fixed;
    bottom: 25vh;
    width: 100vw;
    height: 0px;
    display: flex;
    justify-content: flex-end;

}

#cookies {
    position: relative;
    width: 400px;
    height: 130px;
    margin-right: 5%;
    background-color: white;
    border-radius: 5px;
    border: solid black 3px;
    display: flex;
    flex-wrap: wrap;

}

@media (max-width: 600px) {

#cookie-holder {
        justify-content: center;

    }
    #cookies {
        margin-right: 0;
        height: 130px;
    }
}

#cookies-heading{
    color: black;
    margin-top: 10px;
}

#cookies-pp{
    color:black;
     margin-top: -90px;
     margin-left: 150px;
     line-height: 15px;
}

.cookie-button {
    background-color: black;
    border-radius: 0;
    margin-left: 10px;
    color: white;
    width: 90px;
    height: 30px;
    font-size: 0.9rem;
}

#cookie-button-holder {
    margin-left: 140px;
    margin-top: -30px;
}

#co-jsou-susenky{
    margin-left: 150px;
    margin-top: -48px;
}